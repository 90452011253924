import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Working with the command line can be fun. But the more you use the command line, the more you realise that you end up repeating commands over and over again in your workflow.`}</p>
    <p>{`Luckily there are some easy to remember commands that will help you cut down time when repeating commands. You might also be interested in checking the `}<a parentName="p" {...{
        "href": "../cheatsheets/command-line-shortcuts"
      }}>{`command line shortcut cheatsheet`}</a></p>
    <h2 {...{
      "id": "history----using-reverse-i-search",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#history----using-reverse-i-search",
        "aria-label": "history    using reverse i search permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`History  - Using reverse-i-search`}</h2>
    <p>{`Reverse-i-search will search through the whole `}<inlineCode parentName="p">{`history`}</inlineCode>{` to match a command that contains what you typed. You will need to activate reverse-i-search by pressing the keys `}<inlineCode parentName="p">{`CTRL+R`}</inlineCode>{`, your prompt will switch to `}<inlineCode parentName="p">{`(reverse-i-search)\`':`}</inlineCode></p>
    <p>{`With reverse-i-search activated, you can type the beginning of a command and the last one that was run will be matched. If you want to cycle through all matches you just need to press `}<inlineCode parentName="p">{`CTRL+R`}</inlineCode>{` again.`}</p>
    <p>{`If you know that you are going to use a command a lot you can write the command like this:`}</p>
    <p>{` `}<inlineCode parentName="p">{`<command> #tag`}</inlineCode></p>
    <p>{`Then all you need is run the reverse-i-search and search for `}<inlineCode parentName="p">{`#`}</inlineCode>{`, your command will be matched straight away. Since the # indicates a start of a comment the terminal will simply ignore the text `}<inlineCode parentName="p">{`#tag`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "examples",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#examples",
        "aria-label": "examples permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Examples`}</h3>
    <p>{`History:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`  1  git pull origin master #pull-origin-master
  2  git status
  3  git stash
  4  git pull origin master
  5  git stash apply
  6  git add file
  7  git commit -m "Added file"
  8  git rebase -i HEAD~2
  9  git push
`}</code></pre>
    <p>{`Start reverse-i-search and type git:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`(reverse-i-search)\`git': git push
`}</code></pre>
    <p>{`Git push was the last command typed so that's the first match that reverse-i-search returns.`}</p>
    <p>{`Now let's use the tag trick to run the first command in our history. Let's reverse-i-search for #`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`(reverse-i-search)\`#': git pull origin master #pull-origin-master
`}</code></pre>
    <h2 {...{
      "id": "history---using-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#history---using-",
        "aria-label": "history   using  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`History - Using !`}</h2>
    <p>{`If you run the command `}<inlineCode parentName="p">{`history`}</inlineCode>{` you can see a long list with all the commands that you have typed in the command line. To re-run a command you can do one of these two ways:`}</p>
    <ul>
      <li parentName="ul">{`Type: `}<inlineCode parentName="li">{`!<number of the command>`}</inlineCode></li>
      <li parentName="ul">{`Type `}<inlineCode parentName="li">{`!<first letters of command>`}</inlineCode></li>
    </ul>
    <p>{`For you to use the number of the command you probably would have to run `}<inlineCode parentName="p">{`history`}</inlineCode>{`, then get the number and type `}<inlineCode parentName="p">{`!<number of the command>`}</inlineCode>{`, in some cases this will take even longer than typing the command again.`}</p>
    <p>{`But since you know which command you want to run, you can simply type `}<inlineCode parentName="p">{`!<first letters of command>`}</inlineCode>{` and those first letters will be matched to a command. This little trick can save you some precious time.`}</p>
    <h3 {...{
      "id": "examples-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#examples-1",
        "aria-label": "examples 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Examples`}</h3>
    <p>{`History:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`  1 ls
  2 cd
  3 cd documents/
  4 mkdir folder
  5 rmdir folder
  6 ls
`}</code></pre>
    <p>{`Let's run the last command in our history again by typing `}<inlineCode parentName="p">{`!!`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`user ~/documents > !!
ls
folder
`}</code></pre>
    <p>{`As you can see the terminal will print the command before running it, this is to help you in case something happens that you weren't expecting.`}</p>
    <p>{`Now let's try to run the second command in our history by using the number reference.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`user ~/documents > !2
cd
user ~ >
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`cd`}</inlineCode>{` command was run and you should be in your home directory now.`}</p>
    <p>{`Let's say you want to create a folder with the same name in your home directory you can do:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`user ~ > !mk
mkdir folder
user ~ > ls
folder
`}</code></pre>
    <h2 {...{
      "id": "autocomplete",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#autocomplete",
        "aria-label": "autocomplete permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Autocomplete`}</h2>
    <p>{`Typing long commands can take a while, luckily your terminal can autocomplete commands for you, all you need to do is start typing a few letters and press the `}<inlineCode parentName="p">{`TAB`}</inlineCode>{` key and the command will be auto-completed.`}</p>
    <p>{`If you forgot how do you type a command, but you know the first letter, you can type the first letter and press the `}<inlineCode parentName="p">{`TAB`}</inlineCode>{` key twice, a long list of all available commands will appear and you can use that to help you.`}</p>
    <h3 {...{
      "id": "examples-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#examples-2",
        "aria-label": "examples 2 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Examples`}</h3>
    <p>{`Let's look for a command that starts with the letter K:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`user ~ > K <TAB><TAB>
kadmin.local     kextfind         keytool          klist            kswitch
kcc              kextload         kill             kramdown         ktutil
`}</code></pre>
    <p>{`As you can see any command that starts with the letter K will be returned.`}</p>
    <p>{`I hope you find this helpful, let me know if there are more useful commands that I should add to this list!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      